import { default as energy_45green_45practicesJnaWdfB6H9Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as fun_45part_45journey3bbMYzSq0sMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue?macro=true";
import { default as homesafe_45colorado_45master_45buildertx4Jrvi85FMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as index6Ua8FxfBa9Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45teampWzn0J4GirMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderNtbfSH7rMyMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourceswxsovVPZ2uMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as _91post_93LxQyNYyAREMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexnhXXn6EP3JMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as index0rVYlAdkT0Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93D6HNbH9J9uMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indexQOZtFAqPLfMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as central_45park_45special_45offer3LS00evYrLMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue?macro=true";
import { default as ask_45a_45questionIRgBE3T4SCMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunitiesRVUyYSvqWTMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqZ7kuP1dqxPMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourceszZVJoJcV5ZMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexpaA9KZqTMAMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsdoCkFUOprbMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as current_45offersqGnVXPWdVSMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexFbO8aFDCSBMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexv7tMVrfOcJMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexPz5RvHCU5dMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as index9CdoxC6t5nMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue?macro=true";
import { default as index9nlQLZLz9HMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue?macro=true";
import { default as indexryrdUidprgMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue?macro=true";
import { default as indexKLVtViJJM0Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/index.vue?macro=true";
import { default as knolls_45hoa_45information9Q2o6MVFrlMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue?macro=true";
import { default as land_45acquisitionvSHqOC6YqoMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeMlnB0OiVQmMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceHjECWJwi7WMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_936akldYaEpvMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as indexR8qiXWhbYZMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexDfU8uHJhHOMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessvJ09Dd6MvwMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_93iBnTcD9K0rMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as my_45favoritesk6FVphke1JMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45searchtnxdOAARCgMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as easyhouselp2zWt99pUMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededitionpoN7nxqBAzMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottagemkINPUPaQ0Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexIP9OYAOJv5Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as our_45neighborhoodsiFfzu54ghjMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue?macro=true";
import { default as partner_45with_45us6O8hjAlphgMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue?macro=true";
import { default as privacytermsfCM7Y3btHhMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as sandbox0jKfjQvKZ8Meta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/sandbox.vue?macro=true";
import { default as _91_46_46_46slug_93Xma9uvQUaiMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue?macro=true";
import { default as component_45stub4UTY3Dx1tIMeta } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stub4UTY3Dx1tI } from "/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: energy_45green_45practicesJnaWdfB6H9Meta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesJnaWdfB6H9Meta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesJnaWdfB6H9Meta || {},
    alias: energy_45green_45practicesJnaWdfB6H9Meta?.alias || [],
    redirect: energy_45green_45practicesJnaWdfB6H9Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: fun_45part_45journey3bbMYzSq0sMeta?.name ?? "about-us-fun-part-journey",
    path: fun_45part_45journey3bbMYzSq0sMeta?.path ?? "/about-us/fun-part-journey",
    meta: fun_45part_45journey3bbMYzSq0sMeta || {},
    alias: fun_45part_45journey3bbMYzSq0sMeta?.alias || [],
    redirect: fun_45part_45journey3bbMYzSq0sMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/fun-part-journey.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45buildertx4Jrvi85FMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45buildertx4Jrvi85FMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45buildertx4Jrvi85FMeta || {},
    alias: homesafe_45colorado_45master_45buildertx4Jrvi85FMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45buildertx4Jrvi85FMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: index6Ua8FxfBa9Meta?.name ?? "about-us",
    path: index6Ua8FxfBa9Meta?.path ?? "/about-us",
    meta: index6Ua8FxfBa9Meta || {},
    alias: index6Ua8FxfBa9Meta?.alias || [],
    redirect: index6Ua8FxfBa9Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45teampWzn0J4GirMeta?.name ?? "about-us-our-team",
    path: our_45teampWzn0J4GirMeta?.path ?? "/about-us/our-team",
    meta: our_45teampWzn0J4GirMeta || {},
    alias: our_45teampWzn0J4GirMeta?.alias || [],
    redirect: our_45teampWzn0J4GirMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderNtbfSH7rMyMeta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderNtbfSH7rMyMeta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderNtbfSH7rMyMeta || {},
    alias: preferred_45lenderNtbfSH7rMyMeta?.alias || [],
    redirect: preferred_45lenderNtbfSH7rMyMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourceswxsovVPZ2uMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourceswxsovVPZ2uMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourceswxsovVPZ2uMeta || {},
    alias: realtor_45resourceswxsovVPZ2uMeta?.alias || [],
    redirect: realtor_45resourceswxsovVPZ2uMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: _91post_93LxQyNYyAREMeta?.name ?? "blog-post",
    path: _91post_93LxQyNYyAREMeta?.path ?? "/blog/:post()",
    meta: _91post_93LxQyNYyAREMeta || {},
    alias: _91post_93LxQyNYyAREMeta?.alias || [],
    redirect: _91post_93LxQyNYyAREMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexnhXXn6EP3JMeta?.name ?? "blog-category-category",
    path: indexnhXXn6EP3JMeta?.path ?? "/blog/category/:category()",
    meta: indexnhXXn6EP3JMeta || {},
    alias: indexnhXXn6EP3JMeta?.alias || [],
    redirect: indexnhXXn6EP3JMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: index0rVYlAdkT0Meta?.name ?? "blog",
    path: index0rVYlAdkT0Meta?.path ?? "/blog",
    meta: index0rVYlAdkT0Meta || {},
    alias: index0rVYlAdkT0Meta?.alias || [],
    redirect: index0rVYlAdkT0Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93D6HNbH9J9uMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93D6HNbH9J9uMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93D6HNbH9J9uMeta || {},
    alias: _91_46_46_46slug_93D6HNbH9J9uMeta?.alias || [],
    redirect: _91_46_46_46slug_93D6HNbH9J9uMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexQOZtFAqPLfMeta?.name ?? "campaigns",
    path: indexQOZtFAqPLfMeta?.path ?? "/campaigns",
    meta: indexQOZtFAqPLfMeta || {},
    alias: indexQOZtFAqPLfMeta?.alias || [],
    redirect: indexQOZtFAqPLfMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: central_45park_45special_45offer3LS00evYrLMeta?.name ?? "central-park-special-offer",
    path: central_45park_45special_45offer3LS00evYrLMeta?.path ?? "/central-park-special-offer",
    meta: central_45park_45special_45offer3LS00evYrLMeta || {},
    alias: central_45park_45special_45offer3LS00evYrLMeta?.alias || [],
    redirect: central_45park_45special_45offer3LS00evYrLMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/central-park-special-offer.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionIRgBE3T4SCMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionIRgBE3T4SCMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionIRgBE3T4SCMeta || {},
    alias: ask_45a_45questionIRgBE3T4SCMeta?.alias || [],
    redirect: ask_45a_45questionIRgBE3T4SCMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunitiesRVUyYSvqWTMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunitiesRVUyYSvqWTMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunitiesRVUyYSvqWTMeta || {},
    alias: career_45opportunitiesRVUyYSvqWTMeta?.alias || [],
    redirect: career_45opportunitiesRVUyYSvqWTMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqZ7kuP1dqxPMeta?.name ?? "contact-us-faq",
    path: faqZ7kuP1dqxPMeta?.path ?? "/contact-us/faq",
    meta: faqZ7kuP1dqxPMeta || {},
    alias: faqZ7kuP1dqxPMeta?.alias || [],
    redirect: faqZ7kuP1dqxPMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourceszZVJoJcV5ZMeta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourceszZVJoJcV5ZMeta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourceszZVJoJcV5ZMeta || {},
    alias: homeowner_45resourceszZVJoJcV5ZMeta?.alias || [],
    redirect: homeowner_45resourceszZVJoJcV5ZMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexpaA9KZqTMAMeta?.name ?? "contact-us",
    path: indexpaA9KZqTMAMeta?.path ?? "/contact-us",
    meta: indexpaA9KZqTMAMeta || {},
    alias: indexpaA9KZqTMAMeta?.alias || [],
    redirect: indexpaA9KZqTMAMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsdoCkFUOprbMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsdoCkFUOprbMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsdoCkFUOprbMeta || {},
    alias: our_45locationsdoCkFUOprbMeta?.alias || [],
    redirect: our_45locationsdoCkFUOprbMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: current_45offersqGnVXPWdVSMeta?.name ?? "current-offers",
    path: current_45offersqGnVXPWdVSMeta?.path ?? "/current-offers",
    meta: current_45offersqGnVXPWdVSMeta || {},
    alias: current_45offersqGnVXPWdVSMeta?.alias || [],
    redirect: current_45offersqGnVXPWdVSMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexFbO8aFDCSBMeta?.name ?? "events-event",
    path: indexFbO8aFDCSBMeta?.path ?? "/events/:event()",
    meta: indexFbO8aFDCSBMeta || {},
    alias: indexFbO8aFDCSBMeta?.alias || [],
    redirect: indexFbO8aFDCSBMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexv7tMVrfOcJMeta?.name ?? "events",
    path: indexv7tMVrfOcJMeta?.path ?? "/events",
    meta: indexv7tMVrfOcJMeta || {},
    alias: indexv7tMVrfOcJMeta?.alias || [],
    redirect: indexv7tMVrfOcJMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexPz5RvHCU5dMeta?.name ?? "homes-location-community",
    path: indexPz5RvHCU5dMeta?.path ?? "/homes/:location()/:community()",
    meta: indexPz5RvHCU5dMeta || {},
    alias: indexPz5RvHCU5dMeta?.alias || [],
    redirect: indexPz5RvHCU5dMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: index9CdoxC6t5nMeta?.name ?? "homes-location-community-models-model",
    path: index9CdoxC6t5nMeta?.path ?? "/homes/:location()/:community()/models/:model()",
    meta: index9CdoxC6t5nMeta || {},
    alias: index9CdoxC6t5nMeta?.alias || [],
    redirect: index9CdoxC6t5nMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: index9nlQLZLz9HMeta?.name ?? "homes-location-community-plans-plan",
    path: index9nlQLZLz9HMeta?.path ?? "/homes/:location()/:community()/plans/:plan()",
    meta: index9nlQLZLz9HMeta || {},
    alias: index9nlQLZLz9HMeta?.alias || [],
    redirect: index9nlQLZLz9HMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexryrdUidprgMeta?.name ?? "homes-location-community-qmi-home",
    path: indexryrdUidprgMeta?.path ?? "/homes/:location()/:community()/qmi/:home()",
    meta: indexryrdUidprgMeta || {},
    alias: indexryrdUidprgMeta?.alias || [],
    redirect: indexryrdUidprgMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/homes/[location]/[community]/qmi/[home]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKLVtViJJM0Meta?.name ?? "index",
    path: indexKLVtViJJM0Meta?.path ?? "/",
    meta: indexKLVtViJJM0Meta || {},
    alias: indexKLVtViJJM0Meta?.alias || [],
    redirect: indexKLVtViJJM0Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: knolls_45hoa_45information9Q2o6MVFrlMeta?.name ?? "knolls-hoa-information",
    path: knolls_45hoa_45information9Q2o6MVFrlMeta?.path ?? "/knolls-hoa-information",
    meta: knolls_45hoa_45information9Q2o6MVFrlMeta || {},
    alias: knolls_45hoa_45information9Q2o6MVFrlMeta?.alias || [],
    redirect: knolls_45hoa_45information9Q2o6MVFrlMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/knolls-hoa-information.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionvSHqOC6YqoMeta?.name ?? "land-acquisition",
    path: land_45acquisitionvSHqOC6YqoMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionvSHqOC6YqoMeta || {},
    alias: land_45acquisitionvSHqOC6YqoMeta?.alias || [],
    redirect: land_45acquisitionvSHqOC6YqoMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeMlnB0OiVQmMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeMlnB0OiVQmMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeMlnB0OiVQmMeta || {},
    alias: brents_45placeMlnB0OiVQmMeta?.alias || [],
    redirect: brents_45placeMlnB0OiVQmMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceHjECWJwi7WMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceHjECWJwi7WMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceHjECWJwi7WMeta || {},
    alias: experience_45lower_45maintenanceHjECWJwi7WMeta?.alias || [],
    redirect: experience_45lower_45maintenanceHjECWJwi7WMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_936akldYaEpvMeta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_936akldYaEpvMeta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_936akldYaEpvMeta || {},
    alias: _91testimonial_936akldYaEpvMeta?.alias || [],
    redirect: _91testimonial_936akldYaEpvMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: indexR8qiXWhbYZMeta?.name ?? "lifestyle-reviews-category-category",
    path: indexR8qiXWhbYZMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: indexR8qiXWhbYZMeta || {},
    alias: indexR8qiXWhbYZMeta?.alias || [],
    redirect: indexR8qiXWhbYZMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDfU8uHJhHOMeta?.name ?? "lifestyle-reviews",
    path: indexDfU8uHJhHOMeta?.path ?? "/lifestyle/reviews",
    meta: indexDfU8uHJhHOMeta || {},
    alias: indexDfU8uHJhHOMeta?.alias || [],
    redirect: indexDfU8uHJhHOMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessvJ09Dd6MvwMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessvJ09Dd6MvwMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessvJ09Dd6MvwMeta || {},
    alias: whats_45lifefullnessvJ09Dd6MvwMeta?.alias || [],
    redirect: whats_45lifefullnessvJ09Dd6MvwMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93iBnTcD9K0rMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_93iBnTcD9K0rMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_93iBnTcD9K0rMeta || {},
    alias: _91_46_46_46slug_93iBnTcD9K0rMeta?.alias || [],
    redirect: _91_46_46_46slug_93iBnTcD9K0rMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesk6FVphke1JMeta?.name ?? "my-favorites",
    path: my_45favoritesk6FVphke1JMeta?.path ?? "/my-favorites",
    meta: my_45favoritesk6FVphke1JMeta || {},
    alias: my_45favoritesk6FVphke1JMeta?.alias || [],
    redirect: my_45favoritesk6FVphke1JMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45searchtnxdOAARCgMeta?.name ?? "new-home-search",
    path: new_45home_45searchtnxdOAARCgMeta?.path ?? "/new-home-search",
    meta: new_45home_45searchtnxdOAARCgMeta || {},
    alias: new_45home_45searchtnxdOAARCgMeta?.alias || [],
    redirect: new_45home_45searchtnxdOAARCgMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: easyhouselp2zWt99pUMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhouselp2zWt99pUMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhouselp2zWt99pUMeta || {},
    alias: easyhouselp2zWt99pUMeta?.alias || [],
    redirect: easyhouselp2zWt99pUMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededitionpoN7nxqBAzMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededitionpoN7nxqBAzMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededitionpoN7nxqBAzMeta || {},
    alias: limitededitionpoN7nxqBAzMeta?.alias || [],
    redirect: limitededitionpoN7nxqBAzMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottagemkINPUPaQ0Meta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottagemkINPUPaQ0Meta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottagemkINPUPaQ0Meta || {},
    alias: wee_45cottagemkINPUPaQ0Meta?.alias || [],
    redirect: wee_45cottagemkINPUPaQ0Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexIP9OYAOJv5Meta?.name ?? "our-homes",
    path: indexIP9OYAOJv5Meta?.path ?? "/our-homes",
    meta: indexIP9OYAOJv5Meta || {},
    alias: indexIP9OYAOJv5Meta?.alias || [],
    redirect: indexIP9OYAOJv5Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: our_45neighborhoodsiFfzu54ghjMeta?.name ?? "our-neighborhoods",
    path: our_45neighborhoodsiFfzu54ghjMeta?.path ?? "/our-neighborhoods",
    meta: our_45neighborhoodsiFfzu54ghjMeta || {},
    alias: our_45neighborhoodsiFfzu54ghjMeta?.alias || [],
    redirect: our_45neighborhoodsiFfzu54ghjMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/our-neighborhoods.vue").then(m => m.default || m)
  },
  {
    name: partner_45with_45us6O8hjAlphgMeta?.name ?? "partner-with-us",
    path: partner_45with_45us6O8hjAlphgMeta?.path ?? "/partner-with-us",
    meta: partner_45with_45us6O8hjAlphgMeta || {},
    alias: partner_45with_45us6O8hjAlphgMeta?.alias || [],
    redirect: partner_45with_45us6O8hjAlphgMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/partner-with-us.vue").then(m => m.default || m)
  },
  {
    name: privacytermsfCM7Y3btHhMeta?.name ?? "privacyterms",
    path: privacytermsfCM7Y3btHhMeta?.path ?? "/privacyterms",
    meta: privacytermsfCM7Y3btHhMeta || {},
    alias: privacytermsfCM7Y3btHhMeta?.alias || [],
    redirect: privacytermsfCM7Y3btHhMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: sandbox0jKfjQvKZ8Meta?.name ?? "sandbox",
    path: sandbox0jKfjQvKZ8Meta?.path ?? "/sandbox",
    meta: sandbox0jKfjQvKZ8Meta || {},
    alias: sandbox0jKfjQvKZ8Meta?.alias || [],
    redirect: sandbox0jKfjQvKZ8Meta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Xma9uvQUaiMeta?.name ?? "videos-slug",
    path: _91_46_46_46slug_93Xma9uvQUaiMeta?.path ?? "/videos/:slug(.*)*",
    meta: _91_46_46_46slug_93Xma9uvQUaiMeta || {},
    alias: _91_46_46_46slug_93Xma9uvQUaiMeta?.alias || [],
    redirect: _91_46_46_46slug_93Xma9uvQUaiMeta?.redirect,
    component: () => import("/codebuild/output/src2006932723/src/livebcn-headless/nuxtapp/pages/videos/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/about-us/get-our-brochure/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/about-us/in-the-news/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/about-us/realtor-program/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/about-us/what-we-do/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/about-us/who-we-are/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/build-with-us/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/career-opportunities/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/career-opportunities/administrative-assistant-2/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/career-opportunities/director-of-warranty-services/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/career-opportunities/land-development-entitlements-coordinator/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/contact-us/ask-a-question/thank-you/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/contact-us/warranty-services/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/email-signup/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/get-updates/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/get-updates/thank-you/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/how-to-video-library/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/lifestyle/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/locations/lanterns-at-rock-creek/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/locations/silver-leaf/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/locations/wee-cottage-at-north-end-80238/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/locations/wee-cottage-at-rogers-farm/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/locations/west-edge-at-colliers-hill/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/login/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/lost-password/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/maintenance-items/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/marshall-fire-response/thank-you-loi/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/neighborhoods-map-test/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/organism-page/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/home-collections/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/home-collections/lanterns-series/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/home-collections/opportunities-series/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/gourmet-kitchens/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/home-offices/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/living-rooms/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/outdoor-living-spaces/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/our-homes/photo-gallery/relaxing-master-suites/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/register/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/reset-password/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/resources-lower-maintenance/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/style-guide/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  },
  {
    name: component_45stub4UTY3Dx1tIMeta?.name ?? undefined,
    path: component_45stub4UTY3Dx1tIMeta?.path ?? "/warranty-service-request/",
    meta: component_45stub4UTY3Dx1tIMeta || {},
    alias: component_45stub4UTY3Dx1tIMeta?.alias || [],
    redirect: component_45stub4UTY3Dx1tIMeta?.redirect,
    component: component_45stub4UTY3Dx1tI
  }
]